/* eslint-disable no-unused-vars */
import React from 'react';

import dynamic from 'next/dynamic';
import Head from 'next/head';

import { useDispatch, useSelector } from 'react-redux';

import Wrapper from '../redux';
import { clearCRMData } from '../redux/slices/authSlice/actions';
import { getAllCategories } from '../redux/slices/homeSlice';

import { CONTINUE_PURCHASE_PRODUCTS_STORAGE_KEY, RECENT_VISITED_PRODUCTS_STORAGE_KEY } from '../constants/api.common';
import { javaApi } from '../utils/AxiosInstance';

import styles from '../styles/Home.module.scss';

const MiddleSection = dynamic(() => import('../components/HomePage/CategoryExams.js/MiddleSection'));
const CrackUpscCseComponent = dynamic(() => import('../components/HomePage/CrackUpscCse'));
const CourseSlider = dynamic(() => import('../components/CourseSlider'));
const AIBanner = dynamic(() => import('../components/HomePage/AIBanner'));
const TryNewSection = dynamic(() => import('../components/HomePage/TryNewSection'));
const YouWillGet = dynamic(() => import('../components/HomePage/YouWillGetSection/YouWillGet'));
const GetLink = dynamic(() => import('../components/HomePage/DownloadSection/GetLink'));
const ExploreSection = dynamic(() => import('../components/HomePage/ExploreSection/Explore'));
const Testimonials = dynamic(() => import('../components/HomePage/TestimonialSection/Testimonials'));
const UserLibrary = dynamic(() => import('../components/UserLibrary'), { ssr: false });
const DFPBanner = dynamic(() => import('../components/HomePage/DFPBanner/DFPBanner'));
const FacultyExcellenceProgram = dynamic(() => import('../components/HomePage/FacultyExcellenceProgram'));
const HomepageCourseSlider = dynamic(() => import('../components/HomePage/CourseSlider'));
const PedagogyBanner = dynamic(() => import('../components/HomePage/PedagogyBanner'));

function Home({ homepageData, tryLearnSection, storeCategories }) {
  const dispatch = useDispatch();
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);

  React.useEffect(() => {
    dispatch(clearCRMData());
  }, []);

  return (
    <>
      <Head>
        <title>StudyIQ IAS: Best Online Courses for UPSC IAS and State PCS Exams</title>
        <meta
          name="description"
          content={
            // eslint-disable-next-line max-len
            'StudyIQ IAS provides best online smart courses for UPSC IAS, State PCS and Judiciary exams, with smart features like live classes, doubts management, videos, online test, magazines, and notes in Hindi and English.'
          }
        />
        <meta name="robots" content="index, follow" />
        <meta
          name="keywords"
          content={
            // eslint-disable-next-line max-len
            'ssc cgl, bank po, upsc, ias, cse, aao, mppsc, psc, upsc optional, uppsc, ssc mts, lic hfl, state psc, lic hfl, ctet, clat, law, ugc net, teaching courses, ssc je railways, dmrc, law,general courses, ms excel, class 12 courses, nabard, rbi grade b, defence exams, afcat, capf, nda, bank po, clerk, geography optional'
          }
        />
      </Head>
      <div className={styles.homepage_container}>
        {isAuthenticated === true && <UserLibrary />}

        <CrackUpscCseComponent courses={homepageData?.upsc || []} />

        <CourseSlider
          id="continue-purchase-slider"
          storageKey={CONTINUE_PURCHASE_PRODUCTS_STORAGE_KEY}
          title="Continue Purchase"
          subtitle="Courses you have recently tried to purchase"
        />

        <CourseSlider
          id="recently-viewed-slider"
          storageKey={RECENT_VISITED_PRODUCTS_STORAGE_KEY}
          title="Recently Viewed Courses"
          subtitle="Courses you have recently viewed"
        />
        <HomepageCourseSlider categories={storeCategories} />

        {/* <Tagline headerImages={homepageData} /> */}
        <DFPBanner />
        {isAuthenticated !== true && <YouWillGet />}
        <AIBanner />
        <TryNewSection tryLearnSection={tryLearnSection} />
        <PedagogyBanner />
        <MiddleSection courses={homepageData?.judiciaryAndPcsSection || []} />
        <ExploreSection
          courses={homepageData?.gsAndCurrentAffairsCourses || []}
          blogs={homepageData?.booksAndCurrentAffairsBlogs || []}
        />
        <FacultyExcellenceProgram />
        <Testimonials />
        <GetLink />
      </div>
    </>
  );
}

export const getServerSideProps = Wrapper.getServerSideProps((store) => async (context) => {
  try {
    store.dispatch(getAllCategories());

    const [homepageResponse, contentSectionResponse, categoriesResponse] = await Promise.all([
      javaApi.get('/common-ws/api/v1/web/homepage'),
      javaApi.get('/app-content-ws/v1/homepage/content-sections?fetchActiveOnly=true'),
      javaApi.get('store-ws/api/v1/store/list'),
    ]);

    return {
      props: {
        header: { sticky: false },
        homepageData: homepageResponse?.data?.data || [],
        tryLearnSection: contentSectionResponse?.data?.data ?? [],
        storeCategories: categoriesResponse?.data?.data?.menuList || [],
      },
    };
  } catch (e) {
    return {
      props: {
        header: { sticky: false },
        homepageData: [],
        tryLearnSection: [],
        storeCategories: [],
      },
    };
  }
});

export default Home;
